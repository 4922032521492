

.prod_ucts_{
    /* margin-top: 5em; */
}

.pldkk{
    display: flex !important;
    justify-content: center !important;
}

.futa{
    font-size: 11px;
    text-align: center;
    color: #333;
    position: fixed;
    bottom: 3em;
    user-select: none;
}
.ttttttt{
    margin-bottom: 2em !important;
}

tr{
    border-bottom: 2px solid #eee !important;
}

td{
    /* padding: 0.9em !important; */
    padding-top: 1em;

}


.dld_alll{
    border: none;
    outline: 0;
    background-color: #0d6efd !important;
    color: #fff;
    padding: 2px 9px;
    font-size: 15px;
}

.diff_img{
    width: 60px;
}

@media (max-width:991px) {
    #quer{
        display: none !important;
    }
}
