

.art_frm{
    border: 1px solid #000 !important;
    width: 100vw !important;
    height: 100vh !important;
    position: fixed;
    background: #00000098;
    top: 8.4vh;
    left: 0 !important;
}

.m365{
    width: 500px;
    height: 400px;
}

.img-fluid{
    /* width: 100%; */
    margin-top: -9px;
}

.flc{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.spspsp{
    color: #333;
    font-size: 15px;
}

.close_br{
    font-size: 24px;
    cursor: pointer;
    color: #555;
}

.enta{
    text-align: center;
}

.dkjh{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sharepLog{
    width: 250px;
    margin-left: 1em;
    margin-top: 9px;
}

.enta{
    font-style: italic;
    margin-bottom: -09px;
}